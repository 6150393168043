@import "./_grid_section.css";
@import "./_homepage.css";
@import "./_productpage.css";
@import "./_richtext.css";
@import "./_img_w_txt.css";
@import "./_footer";

@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --font-color: black;
  --bg-color: #f6f6f6;
  --bg-color-rgba-100: rgba(246, 246, 246, 1);
  --bg-color-rgba-95: rgba(246, 246, 246, 0.95);
}

body {
  margin: 0;
  font-family:
    "Quicksand",
    -apple-system,
    BlinkMacSystemFont,
    "Segoe UI",
    "Roboto",
    "Oxygen",
    "Ubuntu",
    "Cantarell",
    "Fira Sans",
    "Droid Sans",
    "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}
