.productpage_container {
  .content_container {
    @apply pt-8 md:pt-0;
    button {
      width: 100%;
      @media (min-width: 768px) {
        width: 10rem;
      }
    }
  }
}
