.richtext_container {
  @apply p-8 md:py-10;

  .richtext_inner {
    @apply max-w-full md:max-w-[550px] m-auto;
    .richtext_txt_head {
      @apply p-5 md:py-5 md:pt-10 md:px-0 text-[20px] md:text-[36px];
      foo:bar;
    }
  }  

  .richtext_txt_body {
    @apply px-0 xs:px-5 sm:px-7;
    /* add click to reveal animation */
    --lineheight: 24px;
    --pad-bottom: 2.5rem;

    display: -webkit-box;
    line-clamp: 2;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    position: relative;
    max-height: calc(var(--lineheight) * 2);
    line-height: var(--lineheight);
    overflow: hidden;
    text-overflow: ellipsis;
    padding-bottom: calc(var(--pad-bottom) + var(--lineheight) * 2);
    &::after {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: calc(100% - var(--lineheight) * 2);
      transition: opacity 0.7s ease-in-out;
      opacity: 1;
    }
    &.clamp {
      max-height: unset;
      line-clamp: unset;
      -webkit-line-clamp: unset;
      padding-bottom: var(--pad-bottom);
      &::after {
        opacity: 0;
      }
    }
  } 
  /* .richtext_txt_body */

  /* zen circle image */
  .addedImg {
      width: 66px;
      height: 66px;
      margin-top: 60px;
      margin-bottom: 20px;
      opacity: 0.1;
      @media (min-width: 768px) {
        width: 100px;
        height: 100px;
        margin-top: 100px;
      }
  }

  &.style_1 {
    @apply py-20;
  }

  &.white_bg {
    background-color: white;
  }
  &.default_bg {
    background-color: var(--bg-color);
  }
} 
/* richtext_container */

.richtext_txt_body {
  .default_bg & {
    &::after {
      background: linear-gradient(to bottom, var(--bg-color-rgba-95) 0%, var(--bg-color-rgba-100) 100%);
    }
  }
  .white_bg & {
    &::after {
      background: linear-gradient(to bottom, rgba(255, 255, 255, 0.95) 0%, rgba(255, 255, 255, 1) 100%);
    }
  }
}
