.grid_section {
  /* ----------------- hover effect ----------------- */
  .grid_txt_container {
    &::before {
      content: "";
      background: transparent;
      transition: opacity 1s ease-in-out;
      opacity: 0;
      @apply w-full h-full absolute top-0 right-0 bottom-0 left-0;
    }
    @apply w-full  absolute bottom-0;
    height: 30%;
  }
  .grid_box_link {
    &:hover {
      .grid_txt_container {
        &::before {
          opacity: 1;
          /* background-position: bottom 0px; */
          background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%);
        }
      }
    }
  }
  /* END: ----------------- hover effect ----------------- */

  @apply max-w-full md:max-w-[1300px] m-auto;
  .grid_box_link {
    @apply border-2 sm:border-4 md:border-8 border-solid border-[var(--bg-color)];
    img {
      @apply aspect-square object-cover;
    }
    .grid_txt_container {
      .grid_box_txt {
        @apply text-xl font-semibold;
        &.product_title {
          width: 200px;
          @media (max-width: 640px) {
            width: 180px;
          }
          @media (min-width: 768px) and (max-width: 1023px) {
            width: 140px;
          }
          @media (min-width: 1024px) {
            width: 250px;
          }
          @apply whitespace-nowrap overflow-hidden text-ellipsis m-auto left-0 right-0;
        }
      }
    }
  }
  &.single_row {
    grid-template-rows: 1fr 0 0 0;
    overflow: hidden;
  }
} /* grid_section */


/* ----------------------------------------------
 * Generated by Animista on 2024-6-30 17:48:12
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation focus-in-contract-bck
 * ----------------------------------------
 */
.focus-in-contract-bck {
  opacity: 0;
  .grid_box_link:hover & {
    -webkit-animation: focus-in-contract-bck 0.7s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
    animation: focus-in-contract-bck 0.7s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  }
}
@-webkit-keyframes focus-in-contract-bck {
  0% {
    letter-spacing: 1em;
    -webkit-transform: translateZ(300px);
    transform: translateZ(300px);
    -webkit-filter: blur(12px);
    filter: blur(12px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateZ(12px);
    transform: translateZ(12px);
    -webkit-filter: blur(0);
    filter: blur(0);
    opacity: 1;
  }
}
@keyframes focus-in-contract-bck {
  0% {
    letter-spacing: 1em;
    -webkit-transform: translateZ(300px);
    transform: translateZ(300px);
    -webkit-filter: blur(12px);
    filter: blur(12px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateZ(12px);
    transform: translateZ(12px);
    -webkit-filter: blur(0);
    filter: blur(0);
    opacity: 1;
  }
}
